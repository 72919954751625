import styled from 'styled-components';

const SectionHeader = styled.h2`
  font-weight: 600;
  line-height: 1.2;
  color: ${(props) => (props.inverted ? 'white' : 'black')};
  text-align: center;
  margin-bottom: 8px;
`;

export default SectionHeader;
