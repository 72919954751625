/* eslint-disable relay/graphql-syntax */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';
import {graphql, navigate} from 'gatsby';
import styled from 'styled-components';
import TopFold from '../../components/Business/TopFold';
import Features from '../../components/Business/Features';
import Pricing from '../../components/Business/Pricing';
// import CaseStudies from '../components/Business/CaseStudy';
import Industries from '../../components/IndustriesSection';
// import ProductInfo from '../../components/Business/ProductInfo';
import FAQ from '../../components/FAQ';
import {Helmet} from 'react-helmet';
import PromotionBar from '../../components/PromotionBar';
import Navbar from '../../components/Business/NavBar';
import Footer from '../../components/Footer';
import IndustryDialog from '../../components/Business/IndustryDialog';
import Quote from '../../components/Quote';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

const Container = styled.div`
  min-height: 100vh;
  background-color: white;
`;

const FAQ_DATA = [
  {
    title: 'Will my client have access to my schedule once they download the app?',
    text: 'No. You will have to invite them by sharing your invite link with them or have them scan your QR code.',
  },
  {
    title: 'What happens if two people book the same time slot?',
    text: 'We make sure this is not possible. We specially put checks in place to only allow booking a time slot that has not been booked by somebody else.',
  },
  {
    title: 'How can get started? How do I create a CindyApp partner account?',
    text: 'Contact us at sales@cindyapp.com.',
  },
  {
    title: 'How can I link my website or Instagram account to CindyApp?',
    text: 'We will generate you a special invite link which you can put on your social media accounts or have a button on your website that leads to it. It is easy as that. Please contact us for more info at sales@cindyapp.com and we will get you started.',
  },
  {
    title: 'How many staff members can I have on my CindyApp account? Do I have to pay more?',
    text: 'You can add unlimited number of staff members as well as unlimited number of services. All of our plans also include unlimited reservations by your clients. You only pay a flat fee, no commision or added costs!',
  },
  {
    title: 'What happens if someone calls me and wants to book an appointment? Can I manually add one?',
    text: 'You sure can! You can book the time slots with 3 clicks using your partner mobile app or the web admin interface.',
  },
  {
    title:
      'I have classes with more than one attendees at regular times throughout the week. Can I still use CindyApp?',
    text: 'Yes, of course! We support recurring time slots. You can also set up a maximum number of attendees for each of your services.',
  },
  {
    title: 'Other questions',
    text: 'Please contact us at sales@cindyapp and we would be happy to answer all of your questions.',
  },
];

const PROMOTION_DATA = [
  {
    text: 'Try for 1 month for FREE!',
    // buttonText: 'Start now',
    link: '/business/sign-up',
    id: 'header-promotion-button',
  },
];

const BusinessPage = () => {
  const [isIndustryModalOpen, setIndustryModalOpen] = useState(false);
  const {t} = useTranslation();

  return (
    <main>
      <Helmet>
        <title>CindyApp - Business</title>
      </Helmet>
      <>
        <Navbar />
      </>
      <PromotionBar data={PROMOTION_DATA} />
      <Container>
        <TopFold />
        <Quote
          text={
            <Trans>
              Our clients experience an average 
              <span style={{fontWeight: 'bold', textDecoration: 'underline'}}>increase of 34%</span> in the total number
              of appointments just 4 months after they start using CindyApp.
            </Trans>
          }
          subtitle={`${t('Nik Telkedzhiev')} | CEO | CindyApp`}
        />
        {/* <ProductInfo /> */}
        <Features id="features" />
        <Industries
          id="industries"
          header={'Industries'}
          subheader={'Suitable for all of the below'}
          onClick={() => setIndustryModalOpen(true)}
        />
        <Pricing id="pricing" onButtonClick={() => navigate('/business/contact-us')} />
        <FAQ data={FAQ_DATA} id="faq" />
        {/* Full size form with customer feedback on the left side like mindbody */}
        {/* <CaseStudies id="case-studies" /> */}
      </Container>
      <Footer />
      <IndustryDialog isOpen={isIndustryModalOpen} onClose={() => setIndustryModalOpen(false)} />
    </main>
  );
};

export default BusinessPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
