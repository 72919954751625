// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';
import {Trans, Link} from 'gatsby-plugin-react-i18next';
import RedSpan from '../RedSpan';
import BusinessImage from '../../assets/images/business-ui.png';
import CTAButton from '../styled/CTAButton';
import Rating from '@mui/material/Rating';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 220px 50px 50px 50px;
  /* height: calc(100vh - 150px); */
  gap: 48px;
  background-color: #392f5a;
  min-height: 440px;

  @media (max-width: 768px) {
    height: auto;
    padding: 136px 24px 24px 24px;
  }
`;

const Heading = styled.h1`
  font-weight: 600;
  color: white;
  line-height: 1.14;
`;

const Subheading = styled.h4`
  color: white;

  @media (min-width: 805px) {
    font-size: 2.8rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
`;

const Image = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  align-items: center;
  object-fit: contain;
  background-image: url(${BusinessImage});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 500px;
`;

// const CTAButton = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   border-radius: 15px;
//   padding: 10px 20px 10px 20px;
//   margin-top: 60px;
//   font-weight: 900;
//   font-size: 0.75em;

//   @media (max-width: 805px) {
//     font-size: 0.9em;
//   }

//   text-decoration: none;
//   background-color: white;
//   color: #8070fd;

//   &:hover {
//     background-color: white;
//     opacity: 0.7;
//     color: #8070fd;
//   }

// &:focus,
// &:hover,
// &:visited,
// &:link,
// &:active {
//   text-decoration: none;
// }
// `;

const InnerContainer = styled.div`
  padding: 32px;

  @media (max-width: 805px) {
    padding: 0px;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 12px;
  border: 1px solid lightgray;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.15);

  @media (max-width: 768px) {
    margin-top: 28px;
  }
`;

const RatingText = styled.div`
  font-size: 0.93em;
  color: white;
  margin-bottom: 6px;
  font-style: italic;
  text-align: center;
`;

const QuoteSubheading = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 32px;
  }

  &:before {
    font-family: Georgia, serif;
    margin-top: -16px;
    font-size: 72px;
    line-height: 1;
    content: '\\201C';
    margin-right: 8px;
    color: white;
  }
`;

function TopFold() {
  return (
    <Container>
      <Column style={{flex: 1}}>
        <InnerContainer>
          <Heading>
            <Trans i18nKey="One solution for <1>all</1> of your existing schedule problems">
              One solution for <RedSpan>all</RedSpan> of your existing schedule problems
            </Trans>
          </Heading>
          <QuoteSubheading>
            <Subheading>
              <Trans>Finally a mobile booking app that works as expected!</Trans>
            </Subheading>
          </QuoteSubheading>
          <RatingContainer>
            <RatingText>
              4.9 <Trans>rating on App Store/Google Play</Trans>
            </RatingText>
            <Rating name="read-only" value={4.9} readOnly size="large" />
          </RatingContainer>
          <CTAButton
            style={{marginTop: 24}}
            $reversed
            as={Link}
            to="/business/sign-up"
            className="sign-up-button"
            id="top-fold-cta-button"
          >
            <Trans>START NOW</Trans>
          </CTAButton>
        </InnerContainer>
      </Column>
      <Column style={{flex: 1}} className="hide-on-mobile">
        <Image />
      </Column>
    </Container>
  );
}

export default TopFold;
