// eslint-disable-next-line no-unused-vars
import React from 'react';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import {Trans} from 'gatsby-plugin-react-i18next';
import CTAButton from '../styled/CTAButton';
import GraphicPhone from '../../assets/images/graphic-phone.png';
import {Link} from '@reach/router';

const Container = styled.div`
  padding: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70vw;
`;

const Text = styled.div`
  font-size: 1.1em;
  text-align: center;
`;

const Image = styled.img`
  margin: 40px 0px 40px 0px;
  max-height: 45vh;
  width: auto;

  @media (max-width: 1092px) {
    margin: 20px 0px 20px 0px;
  }
`;

// const CloseButton = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 2px;
//   padding: 0px;

//   :hover {
//     opacity: 0.4;
//   }
// `;

function IndustryDialog({isOpen, onClose}) {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <Container>
        <Text>
          <Trans>Join now and Make it easy for your clients!</Trans>
        </Text>
        <Image src={GraphicPhone} />
        <CTAButton as={Link} to="/business/sign-up" style={{width: '100%'}}>
          <Trans>Start now</Trans>
        </CTAButton>
      </Container>
    </Dialog>
  );
}

export default IndustryDialog;
