// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Subheader from './Subheader';

const IndustryHeader = styled(Subheader)`
  font-size: 1.6rem;
  margin-top: 0.5em;
  text-align: center;

  @media (max-width: 805px) {
    font-size: 1rem;
    overflow-wrap: break-word;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 18%;
  cursor: pointer;
  align-items: center;
  max-width: 320px;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 560px) {
    flex: 1 0 30%;
  }
`;

const Text = styled.div`
  font-size: 1.2rem;
  color: gray;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

function Industry({image, title, text, onClick}) {
  return (
    <>
      <Container onClick={onClick}>
        <Image src={image} />
        <IndustryHeader>
          <Trans>{title}</Trans>
        </IndustryHeader>
        <Text className="hide-on-mobile">
          <Trans>{text}</Trans>
        </Text>
      </Container>
    </>
  );
}

export default Industry;
