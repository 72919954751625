// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import SectionHeader from '../SectionHeader';
import SectionContainer from '../SectionContainer';
import SectionSubheader from '../SectionSubheader';
import styled from 'styled-components';
import DeviceImage from '../../assets/images/devices/iphone-white.png';
import RedSpan from '../RedSpan';
import FeatureAccordion from './FeatureAccordion';
import FullCalendarIcon from '../../assets/images/icons/19_CINDY_FREE_DATE.svg';
import AccessIcon from '../../assets/images/icons/14_CINDY_LOCKED.svg';
import NotificationsIcon from '../../assets/images/icons/7_CINDY_NOTIFICATION.svg';
import MoneyIcon from '../../assets/images/icons/17_CINDY_MONEY.svg';
import AnalyticsIcon from '../../assets/images/icons/23_CINDY_WAITING.svg';
import Screenshot1 from '../../assets/images/screenshots/provider-appointments-sport.png';
import Screenshot2 from '../../assets/images/screenshots/provider-add-client.png';
import Screenshot3 from '../../assets/images/screenshots/notifications-settings.png';
import Screenshot4 from '../../assets/images/screenshots/checkout.png';
import Screenshot5 from '../../assets/images/screenshots/provider-profile.png';
import ContentContainer from '../styled/ContentContainer';
import './Features.css';

// import Screenshot5 from '../../assets/images/screenshots/screenshot5.png';

// List of features with switchable thumbnails maybe
/*
  - full control over visibility / QR code or invite your clients with a link
  - automatic assignment. Turn on to keep everyone busy
  - reminders for clients. Fees for no show ups
  - payments fully integrated NO COMMISSION cheaper than banks/POS terminals
*/

const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  /* width: 100%; */
  align-items: flex-start;
  gap: 48px;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
  justify-content: flex-start;

  /* // Fine tuning
  @media (max-width: 1092px) {
    height: 500px;
  }

  @media (max-width: 540px) {
    height: 560px;
  }

  @media (max-width: 410px) {
    height: 660px;
  }

  @media (max-width: 350px) {
    height: 700px;
  } */
`;

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
`;

const InnerDeviceContainer = styled.div`
  display: flex;
  flex: 1;
  height: 700px;
`;

const DeviceContainer = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  flex: 1;
`;

const Device = styled.img`
  display: flex;
  flex: 1;
  width: auto;
  object-fit: contain;
  overflow: hidden;
  z-index: 2;
  max-height: 100%;
`;

const Screenshot = styled.div`
  position: absolute;
  width: 280px; // 202
  height: 607px; // 433
  top: 46px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background-size: cover;
  background-image: url(${(props) => props.backgroundImage});
  z-index: 1;
`;

const FeaturesHeading = styled(SectionHeader)`
  @media (min-width: 805px) {
    font-size: 2.6rem;
  }
`;
// const INTERVAL = 4000;

function Features({id}) {
  const [counter, setCounter] = useState(0);

  const handleClick = (key) => {
    return (event) => (counter === key ? setCounter(-1) : setCounter(key));
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCounter((counter + 1) % 5);
  //   }, INTERVAL);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [counter]);

  const screenshotImages = [Screenshot1, Screenshot2, Screenshot3, Screenshot4, Screenshot5];

  return (
    <SectionContainer id={id}>
      <ContentContainer>
        <SectionHeader className="features-header">
          <Trans>A business-centric feature set</Trans>
        </SectionHeader>
        <SectionSubheader className="features-subheader" style={{marginBottom: 56}}>
          <Trans>Let us make your life easier</Trans>
        </SectionSubheader>
        <InnerContainer>
          <DeviceContainer className="features-device">
            <InnerDeviceContainer>
              <Device src={DeviceImage} />
              <Screenshot backgroundImage={screenshotImages[counter]} />
            </InnerDeviceContainer>
          </DeviceContainer>
          <FeaturesContainer>
            <FeaturesHeading>
              <Trans i18nKey="A <1>hands-off approach</1>">
                A <RedSpan>hands-off approach</RedSpan>
              </Trans>
            </FeaturesHeading>
            <SectionSubheader style={{marginTop: 4}}>
              <Trans>
                A special set of features that allows you to work without interruptions while giving your clients
                freedom.
              </Trans>
            </SectionSubheader>
            <FeaturesList>
              <FeatureAccordion
                transitionKey={1}
                isOpen={counter === 0}
                icon={FullCalendarIcon}
                title="Fill your calendar"
                // eslint-disable-next-line max-len
                description="Your clients will be able to book appointments more easily. This will also result in more visits and revenue for your business."
                onClick={handleClick(0)}
              />
              <FeatureAccordion
                transitionKey={2}
                isOpen={counter === 1}
                icon={AccessIcon}
                title="Control access"
                // eslint-disable-next-line max-len
                description="You control who has access to your calendar. Send unique invite links, integrate with your website, or display your QR code on site."
                onClick={handleClick(1)}
              />
              <FeatureAccordion
                transitionKey={3}
                isOpen={counter === 2}
                icon={NotificationsIcon}
                title="Reduce no-shows"
                // eslint-disable-next-line max-len
                description="We make sure to remind both you and your clients about upcoming appointments. You can also enable deposit-only bookings."
                onClick={handleClick(2)}
              />
              <FeatureAccordion
                transitionKey={4}
                isOpen={counter === 3}
                icon={MoneyIcon}
                title="Integrated payments"
                // eslint-disable-next-line max-len
                description="Make it easier for your clients to pay for your services. Benefit from free invoicing services and save up on bank commision fees."
                onClick={handleClick(3)}
              />
              <FeatureAccordion
                transitionKey={5}
                isOpen={counter === 4}
                icon={AnalyticsIcon}
                title="Make it easy for your clients"
                // eslint-disable-next-line max-len
                description="Your clients will be using the same intuative mobile app as you allowing them to book the most convenient time for their next visit."
                onClick={handleClick(4)}
              />
              <FeatureAccordion
                transitionKey={6}
                isOpen={counter === 5}
                icon={AnalyticsIcon}
                title="In-depth reporting"
                // eslint-disable-next-line max-len
                description="Get in-depth analytics data about your business. Handle rush-hours, manage staff better, optimize and improve the customer experience."
                onClick={handleClick(5)}
              />
            </FeaturesList>
          </FeaturesContainer>
        </InnerContainer>
      </ContentContainer>
    </SectionContainer>
  );
}

export default Features;
