// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import './FeatureAccordion.css';
import Chevron from '../Chevron';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border: 2px solid #8070fd;
  border-radius: 8px;
`;

const Icon = styled.img`
  height: 42px;
  width: 42px;
  fill: #ff3660;
  stroke: #ff3660;
  color: #ff3660;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleText = styled.h4`
  font-weight: 600;
  margin-left: 8px;
  flex: 1;
  color: black;
`;

const DescriptionText = styled.div`
  font-size: 0.9em;
  margin-top: 10px;
`;

function FeatureAccordion({title, description, icon, isOpen, onClick, transitionKey}) {
  return (
    <Container onClick={onClick}>
      <TitleContainer>
        <Icon src={icon} />
        <TitleText>
          <Trans>{title}</Trans>
        </TitleText>
        <Chevron />
      </TitleContainer>
      <CSSTransition key={transitionKey} in={isOpen} timeout={100} classNames="feature">
        <>
          {isOpen && (
            <DescriptionText>
              <Trans>{description}</Trans>
            </DescriptionText>
          )}
        </>
      </CSSTransition>
    </Container>
  );
}

export default FeatureAccordion;
