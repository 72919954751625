// eslint-disable-next-line no-unused-vars
import React, {useEffect, useMemo, useState} from 'react';
import {Trans, Link} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import './index.css';

const Container = styled.div`
  width: 100vw;
  /* height: 70px; */
  position: fixed;
  top: 72px;
  background-color: #ff3660;
  color: white;
  z-index: 4;
  overflow: hidden;
  text-align: center;

  @media (max-width: 1092px) {
    top: 52px;
    height: 48px;
  }
`;

const Slider = styled(Link)`
  position: absolute;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px 12px;
  font-size: 1.8rem;

  /* width: calc(100% - 40px); */

  @media (max-width: 805px) {
    /* width: calc(100% - 20px); */
  }
`;

const INTERVAL = 5000;

function PromotionBar({data}) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter((counter + 1) % data.length);
    }, INTERVAL);

    return () => {
      clearTimeout(timer);
    };
  }, [counter, data]);

  const currentSlider = data[counter];

  const {outsideLink} = currentSlider;

  return (
    <Container>
      <TransitionGroup className="item-container">
        <CSSTransition key={counter} timeout={1000} classNames="item">
          <Slider
            as={outsideLink ? 'a' : Link}
            id={currentSlider.id}
            href={currentSlider.link}
            target="_blank"
            to={currentSlider.link}
          >
            <Trans>{currentSlider.text}</Trans>
            <ArrowForwardIcon style={{marginLeft: 4, width: 24, height: 24}} />
            {/* <Button
              $reverse
              $border={!!currentSlider.buttonText}
              style={{marginLeft: 10, fontSize: '0.9em', backgroundColor: '#ff3660'}}
            >
              {currentSlider.buttonText && (
                <div className="promo-title">
                  <Trans>{currentSlider.buttonText}</Trans>
                </div>
              )}
              <ArrowForwardIcon className="promo-arrow" />
            </Button> */}
          </Slider>
        </CSSTransition>
      </TransitionGroup>
    </Container>
  );
}

export default PromotionBar;
