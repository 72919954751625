// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Industry from './Industry';
import YogaImage from '../assets/images/industries/yoga.jpg';
import MotoImage from '../assets/images/industries/moto.jpg';
import BeautyImage from '../assets/images/industries/beauty.jpg';
import SpaImage from '../assets/images/industries/spa.jpg';
import HealthCareImage from '../assets/images/industries/healthcare.jpg';
import ServicesImage from '../assets/images/industries/services.jpg';
import EducationImage from '../assets/images/industries/education.jpg';
import LegalImage from '../assets/images/industries/legal.jpg';
import OtherImage from '../assets/images/industries/other.jpg';
import SectionHeader from './SectionHeader';
import SectionSubheader from './SectionSubheader';
import SectionContainer from './SectionContainer';
import ContentContainer from './styled/ContentContainer';

const IndustriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  gap: 24px;

  @media (max-width: 805px) {
    gap: 8px;
  }
`;

function IndustriesSection({id, header, subheader, onClick}) {
  return (
    <SectionContainer id={id}>
      <ContentContainer style={{maxWidth: 1152}}>
        <SectionHeader>
          <Trans>{header}</Trans>
        </SectionHeader>
        <SectionSubheader>
          <Trans>{subheader}</Trans>
        </SectionSubheader>
        <IndustriesContainer>
          <Industry refId={3} image={YogaImage} title={'Sports'} text={'Yoga, tennis, gyms'} onClick={onClick} />
          <Industry
            image={BeautyImage}
            refId={1}
            title={'Beauty'}
            text={'Beauty salons, Hair and nails'}
            onClick={onClick}
          />
          <Industry
            refId={12}
            image={SpaImage}
            title={'Spa & Therapy'}
            text={'Massage, sauna, therapy'}
            onClick={onClick}
          />
          <Industry
            refId={8}
            image={MotoImage}
            title={'Auto'}
            text={'Oil change, maintanance, paint'}
            onClick={onClick}
          />
          <Industry
            refId={13}
            image={ServicesImage}
            title={'Services'}
            text={'Cleaning, and other'}
            onClick={onClick}
          />
          <Industry
            refId={14}
            image={EducationImage}
            title={'Education'}
            text={'Group and/or individual lessons'}
            onClick={onClick}
          />
          <Industry
            refId={4}
            image={HealthCareImage}
            title={'Health Care'}
            text={'Doctors, dentists, dermatologists'}
            onClick={onClick}
          />
          <Industry refId={15} image={LegalImage} title={'Legal'} text={'Conseling, accounting'} onClick={onClick} />
          <Industry refId={16} image={OtherImage} title={'Other'} text={'Other'} onClick={onClick} />
        </IndustriesContainer>
      </ContentContainer>
    </SectionContainer>
  );
}

export default IndustriesSection;
